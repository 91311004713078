import homepagehero from "./homepagehero.jpg"
import homecaro1 from "./homecaro1.jpg"
import homecaro2 from "./homecaro2.jpg"
import homecaro3 from "./homecaro3.jpg"
import homecaro4 from "./homecaro4.jpg"
import ski from "./ski.jpg"
import rando from "./rando.jpg"
import run from "./run.jpg"
import daily from "./daily.jpg"
import run3dprotect from "./run-3d-protect.jpg"
import threefeetoutdoormid from "./3feet-outdoor-mid.jpg"
import trailrunningprotectsocksblackyellow from "./trail-running-protect-socks-blackyellow.jpg"
import antifrictioncream from "./anti-friction-cream.jpg"
import sidasworldwide from "./sidasworldwide.jpg"

import './App.css';
import { Carousel, Col, Divider, Image, Row } from "antd"

function App() {
  return (
    <div className="App">
      <header >
      

      <Carousel autoplay>
    <div>
      <Image preview={false} src={homecaro1}/>
    </div>
    <div>
      <Image preview={false} src={homecaro2}/>
    </div>
    <div>
      <Image preview={false} src={homecaro3}/>
    </div>
    <div>
      <Image preview={false} src={homecaro4}/>
    </div>
  </Carousel>

<div className="w-screen bg-theme-eee">

      <div className="container mx-auto px-6 py-10  space-y-6 lg:h-2/3 lg:py-16 lg:flex-row items-center">
        <p className="uppercase text-4xl font-bold">
        THE FEET SPECIALIST
        </p>
        <p className=" text-xl font-medium text-gray-600">
        Discover our universe
        </p>
<br></br>
<br></br>

            <Row>
              <Col span={window.innerHeight>window.innerWidth ?24:6}>
              <Image preview={false} src={ski} className="hover:-translate-y-6 transition delay-100"/>
              <center> <p className=" text-xl font-medium text-gray-800">
        Ski
        </p></center>
              </Col>
              <Col span={window.innerHeight>window.innerWidth ?24:6}>
              <Image preview={false} src={rando} className="hover:-translate-y-6 transition delay-100"/>
              <center> <p className=" text-xl font-medium text-gray-800">
        Outdoor
        </p></center>
              </Col>
              <Col span={window.innerHeight>window.innerWidth ?24:6}>
              <Image preview={false} src={run} className="hover:-translate-y-6 transition delay-100"/>
              <center> <p className=" text-xl font-medium text-gray-8800">
       Running
        </p></center>
              </Col>
              <Col span={window.innerHeight>window.innerWidth ?24:6}>
              <Image preview={false} src={daily} className="hover:-translate-y-6 transition delay-100"/>
              <center> <p className=" text-xl font-medium text-gray-800">
        Daily Life
        </p></center>
              </Col>
              
            </Row>
        </div>
</div>
<div className="w-screen bg-white">

      <div className="container mx-auto px-6 py-10  space-y-6 lg:h-2/3 lg:py-16 lg:flex-row items-center">
        <p className="uppercase text-4xl font-bold">
        Most popular
        </p>
        <Divider></Divider>
<br></br>
<br></br>

            <Row>

              
              <Col span={window.innerHeight>window.innerWidth ?24:6}>
              <Image preview={false} src={run3dprotect} className="hover:scale-110 hover:mb-5 duration-200 transition delay-100"/>
              <center>  
                <p className=" text-xl font-medium text-theme-gold uppercase"> Run 3d protect </p>
                <p className=" text-sm font-medium text-black">  Running insoles  </p>
                <p className=" text-2xl font-medium text-gray-600">   5,500BDT  </p> </center>
              </Col>

              <Col span={window.innerHeight>window.innerWidth ?24:6}>
              <Image preview={false} src={threefeetoutdoormid} className="hover:scale-110 hover:mb-5 duration-200 transition delay-100"/>
              <center>  
                <p className=" text-xl font-medium text-theme-gold uppercase"> 3FEET® OUTDOOR - MID </p>
                <p className=" text-sm font-medium text-black">  Hiking insoles  </p>
                <p className=" text-2xl font-medium text-gray-600">   6,100BDT  </p> </center>
              </Col>

              <Col span={window.innerHeight>window.innerWidth ?24:6}>
              <Image preview={false} src={trailrunningprotectsocksblackyellow} className="hover:scale-110 hover:mb-5 duration-200 transition delay-100"/>
              <center>  
                <p className=" text-xl font-medium text-theme-gold uppercase"> TRAIL PROTECT BLACK/YELLOW </p>
                <p className=" text-sm font-medium text-black">  Trail running socks  </p>
                <p className=" text-2xl font-medium text-gray-600">   2,500BDT  </p> </center>
              </Col>

              <Col span={window.innerHeight>window.innerWidth ?24:6}>
              <Image preview={false} src={antifrictioncream} className="hover:scale-110 hover:mb-5 duration-200 transition delay-100"/>
              <center>  
                <p className=" text-xl font-medium text-theme-gold uppercase"> Anti Friction Cream </p>
                <p className=" text-sm font-medium text-black">  Anti chafing cream  </p>
                <p className=" text-2xl font-medium text-gray-600">   2,100BDT  </p> </center>
              </Col>

              
            </Row>
        </div>
</div>

<div class="h-1/2 bg-gray-50 flex items-center">
	<section class="bg-cover bg-center py-32 w-full" style={{backgroundImage: `url(${sidasworldwide})` }} >
		<div class="container mx-auto text-left text-white">
			<div class="flex items-center text-center">
				<div class="w-1/2">
					<h1 class="text-5xl font-bold text-black mb-6 uppercase">Sidas</h1>
					<h1 class="text-5xl font-bold text-black mb-6 uppercase">Worldwide</h1>
					<p class="text-2xl text-black mb-12">Live the Sidas experience in-store

</p>
					<a href="#" class="bg-theme-yellow text-black font-bold py-4 px-12 rounded-full uppercase">{`Find a store >`}</a>
				</div>
				
				</div>
			</div>
	</section>
</div>


      <div class="container flex flex-col px-6 py-10 mx-auto space-y-6 lg:h-[32rem] lg:py-16 lg:flex-row lg:items-center">
            <div class="w-full lg:w-1/2">
                <div class="lg:max-w-lg">
                    <h1 class="text-3xl font-bold text-start tracking-wide text-gray-800 dark:text-black lg:text-5xl">
                        PRODUCTS
                    </h1>
                    <p class="text-xl font-medium text-start tracking-wide text-gray-800 dark:text-black ">
                    Products for the well being of your feet: discover our complete range, from socks to insoles, to custom ski liners and accessories.
                    </p>
                    <br></br>
                    <p class="text-xl font-bold text-start tracking-wide text-gray-800 dark:text-black ">
                    <a href="./products">{`READ MORE >`}</a>
                    </p>
                
                </div>

                
            </div>
    
            <div class="flex items-center justify-center w-full h-96 lg:w-1/2">
                <img class="object-cover w-full h-full mx-auto  lg:max-w-2xl" src={homepagehero} alt="Sidas Bangladesh"/>
            </div>
        </div>
      </header>
    </div>
  );
}

export default App;
