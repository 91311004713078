import { Divider, Drawer, Image } from "antd"
import logo from "../logo.png"
import { useState } from "react";

const Navbar=()=>{
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
    return(
      <div>
        <div className="h-200 bg-theme-yellow p-2 text-center font-bold uppercase text-sm ">
 {`My feet hurt  >`}
        </div>
          <nav class="flex text-black items-center relative justify-between bg-white px-2 pt-6 w-4/6  m-auto">
  <div >

  <Drawer title="Menu" placement="right" className="list-none" onClose={onClose} open={open}>
  <form class="flex items-center">   
    <label for="voice-search" class="sr-only">Search</label>
    <div class="relative w-full">
        <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
        </div>
        <input type="text" id="voice-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" required={true}/>
      
    </div>
    <button type="submit" class="inline-flex items-center py-2.5 px-3 ml-2 text-sm font-medium text-white bg-blue-700 border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
        <svg aria-hidden="true" class="mr-2 -ml-1 w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>Search
    </button>
</form>
    <Divider ></Divider>
  <li class="font-medium text-l duration-300 font-bold font-sans border-b-8 border-transparent hover:border-current transition hover:border-yellow-400 cursor-pointer  uppercase p-3   sm:p-0  ">
      <a href="./products" class="p-1  select-none">
        Products</a>
    </li>
    <li class="font-medium text-l duration-300 font-bold font-sans border-b-8 border-transparent hover:border-current transition hover:border-yellow-400 cursor-pointer uppercase p-3   sm:p-0  ">
      <a href="./activities" class="p-1  select-none">
        Activities</a>
    </li>
    <li class="font-medium text-l duration-300 font-bold font-sans border-b-8 border-transparent hover:border-current transition hover:border-yellow-400 cursor-pointer uppercase p-3   sm:p-0  ">
      <a href="./new" class="p-1  select-none">
        New</a>
    </li>
    <li class="font-medium text-l duration-300 font-bold font-sans border-b-8 border-transparent hover:border-current transition hover:border-yellow-400 cursor-pointer uppercase p-3   sm:p-0  ">
      <a href="./community" class="p-1  select-none">
        Community</a>
    </li>
    <li class="font-medium text-l duration-300 font-bold font-sans border-b-8 border-transparent hover:border-current transition hover:border-yellow-400 cursor-pointer uppercase p-3   sm:p-0  ">
      <a href="./blog" class="p-1  select-none">
        Blog</a>
    </li>
    <li class="font-medium text-l duration-300 font-bold font-sans border-b-8 border-transparent hover:border-current transition hover:border-yellow-400 cursor-pointer uppercase p-3   sm:p-0  ">
      <a href="./brand" class="p-1  select-none">
        Our Brand</a>
    </li>
      </Drawer>
      <div className=" list-none hidden sm:flex">
      <li class="font-medium text-l duration-300 font-bold font-sans border-b-8 border-transparent hover:border-current transition hover:border-yellow-400 cursor-pointer uppercase p-3   sm:p-0  ">
      <a href="./blog" class="p-1  select-none">
        Contact</a>
    </li>
    <li class="font-medium text-l duration-300 font-bold font-sans border-b-8 border-transparent hover:border-current transition hover:border-yellow-400 cursor-pointer uppercase p-3   sm:p-0  ">
      <a href="./brand" class="p-1  select-none">
        Newsletter</a>
    </li>
      </div>
 <Image  onClick={()=>{window.location = "./home"}} preview={false} src={logo}width={window.innerHeight>window.innerWidth?"90%":"0%"} />
 
  </div>








  <div class=" flex gap-3 items-center ">
    <svg aria-hidden="true" class="w-5 h-5 hidden sm:block" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path  className="fill-black hidden sm:block" fill-rule="evenodd" clip-rule="evenodd" d="M16.9303 7C16.9621 6.92913 16.977 6.85189 16.9739 6.77432H17C16.8882 4.10591 14.6849 2 12.0049 2C9.325 2 7.12172 4.10591 7.00989 6.77432C6.9967 6.84898 6.9967 6.92535 7.00989 7H6.93171C5.65022 7 4.28034 7.84597 3.88264 10.1201L3.1049 16.3147C2.46858 20.8629 4.81062 22 7.86853 22H16.1585C19.2075 22 21.4789 20.3535 20.9133 16.3147L20.1444 10.1201C19.676 7.90964 18.3503 7 17.0865 7H16.9303ZM15.4932 7C15.4654 6.92794 15.4506 6.85153 15.4497 6.77432C15.4497 4.85682 13.8899 3.30238 11.9657 3.30238C10.0416 3.30238 8.48184 4.85682 8.48184 6.77432C8.49502 6.84898 8.49502 6.92535 8.48184 7H15.4932ZM9.097 12.1486C8.60889 12.1486 8.21321 11.7413 8.21321 11.2389C8.21321 10.7366 8.60889 10.3293 9.097 10.3293C9.5851 10.3293 9.98079 10.7366 9.98079 11.2389C9.98079 11.7413 9.5851 12.1486 9.097 12.1486ZM14.002 11.2389C14.002 11.7413 14.3977 12.1486 14.8858 12.1486C15.3739 12.1486 15.7696 11.7413 15.7696 11.2389C15.7696 10.7366 15.3739 10.3293 14.8858 10.3293C14.3977 10.3293 14.002 10.7366 14.002 11.2389Z" fill="#200E32" />
    </svg>
     

    <div className="hidden sm:block h-10 w-10 hover:ring-4 user cursor-pointer relative ring-blue-700/30 rounded-full bg-cover bg-center bg-[url('https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80')]">
      
    
    </div>
    <div className="sm:hidden cursor-pointer" id="mobile-toggle"  onClick={showDrawer}>
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path class="dark:stroke-black" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
      </svg>
    </div>
  </div>
</nav>
<Divider className="hidden sm:block"></Divider>
<nav class="container pb-6 mx-auto lg:flex lg:justify-between w-4/6 lg:items-center">
            <div class="flex items-center justify-between">
                <div>
                <Image onClick={()=>{window.location = "./home"}} preview={false} src={logo}width={window.innerHeight>window.innerWidth?"0%":"50%"} />

                </div>
    
           
            </div>
    
            <div class="flex flex-col hidden sm:flex mt-4 space-y-5 lg:-mx-6 lg:mt-0 lg:flex-row lg:space-y-0 list-none">
            <li class="font-medium text-l duration-300 font-bold font-sans border-b-8 border-transparent hover:border-current transition hover:border-yellow-400 cursor-pointer  uppercase p-3   sm:p-0  ">
      <a href="./products" class="p-1 m-1 select-none">
        Products</a>
    </li>
    <li class="font-medium text-l duration-300 font-bold font-sans border-b-8 border-transparent hover:border-current transition hover:border-yellow-400 cursor-pointer uppercase p-3   sm:p-0  ">
      <a href="./activities" class="p-1 m-1 select-none">
        Activities</a>
    </li>
    <li class="font-medium text-l duration-300 font-bold font-sans border-b-8 border-transparent hover:border-current transition hover:border-yellow-400 cursor-pointer uppercase p-3   sm:p-0  ">
      <a href="./new" class="p-1 m-1 select-none">
        New</a>
    </li>
    <li class="font-medium text-l duration-300 font-bold font-sans border-b-8 border-transparent hover:border-current transition hover:border-yellow-400 cursor-pointer uppercase p-3   sm:p-0  ">
      <a href="./community" class="p-1 m-1 select-none">
        Community</a>
    </li>
    <li class="font-medium text-l duration-300 font-bold font-sans border-b-8 border-transparent hover:border-current transition hover:border-yellow-400 cursor-pointer uppercase p-3   sm:p-0  ">
      <a href="./blog" class="p-1 m-1 select-none">
        Blog</a>
    </li>
    <li class="font-medium text-l duration-300 font-bold font-sans border-b-8 border-transparent hover:border-current transition hover:border-yellow-400 cursor-pointer uppercase p-3   sm:p-0  ">
      <a href="./brand" class="p-1 m-1 select-none">
        Our Brand</a>
    </li>
            </div>
    
            <a class="block text-center text-gray-700 capitalize transition-colors duration-200 transform border rounded-md dark:hover:bg-gray-700 dark:text-white lg:mt-0 hover:bg-gray-100 lg:w-auto"
                href="#">
                
            </a>
        </nav>
      </div>
    )
}

export default Navbar