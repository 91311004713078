import homepagehero from "./homepagehero.jpg"

import run3dprotect from "./run-3d-protect.jpg"
import threefeetoutdoormid from "./3feet-outdoor-mid.jpg"
import trailrunningprotectsocksblackyellow from "./trail-running-protect-socks-blackyellow.jpg"
import antifrictioncream from "./anti-friction-cream.jpg"

import './App.css';
import { Carousel, Col, Divider, Image, List, Row } from "antd"


const products = [
    {
        image:run3dprotect,
        title:"RUN 3D PROTECT",
        category:"RUN 3D PROTECT",
        price:"5,500BDT"
    }
]


function Products() {
  return (
    <div className="App">
      <header >
      

      <div class="container flex flex-col px-6 py-10 mx-auto space-y-6 lg:h-[32rem] lg:py-16 lg:flex-row lg:items-center">
            <div class="w-full lg:w-1/2">
                <div class="lg:max-w-lg">
                    <h1 class="text-3xl font-bold text-start tracking-wide text-gray-800 dark:text-black lg:text-5xl">
                        PRODUCTS
                    </h1>
                    <p class="text-xl font-medium text-start tracking-wide text-gray-800 dark:text-black ">
                    Products for the well being of your feet: discover our complete range, from socks to insoles, to custom ski liners and accessories.
                    </p>
                    <br></br>
                    <p class="text-xl font-bold text-start tracking-wide text-gray-800 dark:text-black ">
                    <a href="./products">{`READ MORE >`}</a>
                    </p>
                
                </div>

                
            </div>
    
            <div class="flex items-center justify-center w-full h-96 lg:w-1/2">
                <img class="object-cover w-full h-full mx-auto  lg:max-w-2xl" src={homepagehero} alt="Sidas Bangladesh"/>
            </div>
        </div>
   
<div className="w-screen bg-white">

      <div className="container mx-auto px-6 py-10  space-y-6 lg:h-2/3 lg:py-16 lg:flex-row items-center">
        <p className="uppercase text-4xl font-bold">
        Our Products
        </p>
        <Divider></Divider>
<br></br>
<br></br>

            <Row>

           {products.map((item)=>{

return(<Col span={window.innerHeight>window.innerWidth ?24:6}>
    <Image preview={false} src={item.image} className="hover:scale-110 hover:mb-5 duration-200 transition delay-100"/>
    <center>  
      <p className=" text-xl font-medium text-theme-gold uppercase">{item.title} </p>
      <p className=" text-sm font-medium text-black">  {item.category}  </p>
      <p className=" text-2xl font-medium text-gray-600">   {item.price}  </p> </center>
    </Col>)
           })}
              
            </Row>
        </div>
</div>



      </header>
    </div>
  );
}

export default Products;
